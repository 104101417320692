<template>
  <v-dialog v-model="dialog" max-width="700" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Currency</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Nominal</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <v-menu
                  v-model="menuDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="item.date"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="item.date" @input="menuDate = false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Buy Price</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <v-text-field
                  dense
                  outlined
                  flat
                  type="number"
                  v-model.number="item.toIDR"
                  :rules="[v => (!!v && v >= 0) || 'This field is required']"
                  :hint="formatPrice(item.toIDR)"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Middle Price</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <v-text-field
                  dense
                  outlined
                  flat
                  type="number"
                  v-model.number="item.middlePrice"
                  :rules="[v => (!!v && v >= 0) || 'This field is required']"
                  :hint="formatPrice(item.middlePrice)"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Sell Price</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <v-text-field
                  dense
                  outlined
                  flat
                  type="number"
                  v-model.number="item.sellPrice"
                  :rules="[v => (!!v && v >= 0) || 'This field is required']"
                  :hint="formatPrice(item.sellPrice)"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close">
          Close
        </v-btn>
        <v-btn color="primary" @click="save">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-currency",
  props: {
    dialog: Boolean,
    item: Object,
  },
  data() {
    return {
      menuDate: false,
    };
  },
  methods: {
    save() {
      this.$emit("save", this.item);
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
