<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="currencies"
      sort-by="no"
      dense
      @click:row="detailCurrency"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="success" v-on="on" dark rounded :to="{ name: 'Create Currency' }">
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-btn>
            </template>
            <span>Create</span>
          </v-tooltip>
        </v-toolbar>
      </template>
      <template v-slot:item.no="{ item }">
        {{ currencies.map(x => x.id).indexOf(item.id) + 1 }}
      </template>
      <template v-slot:item.country="{ item }">
        {{ item.country ? item.country.name : "" }}
      </template>
      <template v-slot:item.middlePrice="{ item }">
        {{ formatPrice(item.middlePrice) }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="success" v-on="on" @click.stop="open(item)" small>
              <v-icon>
                mdi-cash
              </v-icon>
            </v-btn>
          </template>
          <span>Update Rate</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">
          Reset
        </v-btn>
      </template>
    </v-data-table>
    <dialog-currency
      :dialog="dialog"
      :item="item"
      @save="updateNominal"
      @close="close"
    ></dialog-currency>
  </div>
</template>

<script>
import DialogCurrency from "./DialogCurrency.vue";

export default {
  name: "currency",
  components: {
    DialogCurrency,
  },
  data: () => ({
    headers: [
      {
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Name",
        value: "name",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Symbol",
        value: "symbol",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Code",
        value: "code",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
      // {
      //   text: "Country",
      //   value: "country",
      //   sortable: false,
      //   divider: true,
      //   align: "center",
      //   class: "light-blue lighten-2 font-weight-black",
      // },
      {
        text: "Bank Reference",
        value: "bankReference",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Middle Price",
        value: "middlePrice",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
    item: {},
    currencies: [],
    dialog: false,
  }),

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("currency/getAll")
        .then(response => {
          this.currencies = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    detailCurrency(item) {
      this.$router.push({ name: "Detail Currency", params: { id: item.id } });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    open(item) {
      this.item = item;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    async updateNominal(item) {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("currency/updateNominal", item)
        .then(() => {
          this.dialog = false;
          this.$store.commit("SET_LOADER", false);
          this.initialize();
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
};
</script>

<style></style>
